import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  padding: 100px 0;
  box-sizing: border-box;

  ${mediaMax.md} {
    height: auto;
    min-height: 100vh;
  }
`

export const Wrapper = styled.div`
  padding-top: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 7rem;

  ${mediaMax.md} {
    gap: 5rem;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  ${mediaMax.md} {
    gap: 1.5rem;
  }
`

export const Count = styled.div`
  color: ${({ theme }) => theme.colors.lightGreen2};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;
  letter-spacing: .15em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity ease .3s;
  opacity: 0;

  ${mediaMax.md} {
    gap: 1rem;
  }

  &.enter {
    opacity: 1;
    transition: opacity ease .3s .3s;

    &.hide {
      opacity: 0;
      transition: opacity ease .3s;
    }
  }

  &.hide {
    opacity: 0;
  }
`

export const CountValue = styled.div`
  opacity: 1;
  transition: opacity ease .3s;

  &.change {
    opacity: 0;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.brown};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-weight: bold;
  font-size: 4.5rem;
  text-align: center;
  transition: opacity ease .3s;
  opacity: 0;

  &.enter {
    opacity: 1;
    transition: opacity ease .3s .3s;

    &.hide {
      opacity: 0;
      transition: opacity ease .3s;
    }
  }

  &.hide {
    opacity: 0;
  }

  ${mediaMax.md} {
    font-size: 2.5rem;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.8rem;

  ${mediaMax.md} {
    gap: 2rem;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity ease .3s .7s;

  &.change {
    opacity: 0;
    transition: opacity ease .3s;
  }


  ${mediaMax.md} {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`
