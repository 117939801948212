import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react'
import { useTheme } from '@emotion/react'
import PropTypes from 'prop-types'
import TextSplit from '@/libs/TextSplit'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  Content,
  Text,
  Choice,
  ChoiceContent,
  ChoiceIcon,
  ChoiceLabel,
  Choices,
  Item,
  ItemLabel,
  ItemImages,
  ItemImageRegular,
  ItemImageColored,
  IconWrapper
} from './style'

import Icon from '@/components/Icon'

const Question = forwardRef(({ text = '', options = [] }, ref) => {
  const [appear, setAppear] = useState(false)
  const [hide, setHide] = useState(false)
  const [status, setStatus] = useState(false)
  const [index, setIndex] = useState(0)
  const split = useRef(null)
  const { colors } = useTheme()

  useImperativeHandle(ref, () => ({
    enter () {
      initSplit()

      setTimeout(() => {
        setAppear(true)
      }, 500)
    },
    leave () {
      setHide(true)
    },
    hide () {
      setHide(true)
    },
    show () {
      setIndex(0)
      initSplit()
      setHide(false)
    },
    choice () {
      return options[index]
    },
    test () {
      initSplit()
    }
  }))

  const initSplit = () => {
    if (TextSplit.isSplitted(split.current)) {
      TextSplit.reset(split.current, text)
    }

    TextSplit.split(split.current)
  }

  const handleStatus = () => {
    setStatus(!status)
  }

  const handleChoice = (i) => {
    setIndex(i)
    setStatus(false)
  }

  return (
    <Container>
      <Content className={ `${appear && 'appear'} ${hide && 'hide'}` }>
        <Text ref={ split }>
          { text }
        </Text>
        <Choice className={ !status && 'visible' } onClick={ handleStatus }>
          <ChoiceIcon loading='lazy' src={ resolveAssetURL(options[index].image.url) } alt={ options[index].image.alt } />
          <ChoiceContent>
            <ChoiceLabel>
              { options[index].label }
            </ChoiceLabel>
            <IconWrapper>
              <Icon
                color={ colors.lightGreen2 }
                size={ 30 }
                name='arrow-bottom'
              />
            </IconWrapper>
          </ChoiceContent>
        </Choice>
      </Content>
      <Choices className={ !status && 'hidden' }>
        {
          options.map((o, i) => (
            <Item key={ `choice-${i}` } onClick={ () => handleChoice(i) } color={ o.color }>
              <ItemImages index={ i } length={ options.length }>
                <ItemImageRegular loading='lazy' src={ resolveAssetURL(o.image.url) } alt={ o.image.alt } />
                <ItemImageColored loading='lazy' src={ resolveAssetURL(o.image_colored.url) } alt={ o.image_colored.alt } />
              </ItemImages>
              <ItemLabel index={ i }>
                {o.label}
              </ItemLabel>
            </Item>
          ))
        }
      </Choices>
    </Container>
  )
})

Question.propTypes = {
  text: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string
  }))
}

export default Question
