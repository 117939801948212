import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import Layout from '@/layouts/Default'

import WineFinder from '@/components/WineFinder'
import WineFinderManager from '../utils/WineFinderManager'

const WineFinderPage = ({ data, pageContext: { marketCode: pageMarketCode } }) => {
  const [display, setDisplay] = useState(true)

  const reset = async () => {
    await setDisplay(false)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    await setDisplay(true)
  }

  return (
    <Layout
      entityBundle='wine_finder'
      footer
      plainHeader
      noPadding
      pageType='Wine Finder'
      pageMarketCode={ pageMarketCode }
    >
      {display && <WineFinder data={ WineFinderManager.parse(data) } reset={ reset } />}
    </Layout>
  )
}

export const query = graphql`
  query WineFinderQuery($nid: String!) {
    drupal {
      nodeById(id: $nid) {
        ... on Drupal_NodeWineFinder {
          fieldWineFinderQuestions {
            entity {
              ... on Drupal_ParagraphWineFinderQuestion {
                id
                fieldWineFinderQuestionLabel
                fieldWineFinderAAnswer
                fieldWineFinderAAnswerAlt {
                  url
                  alt
                }
                fieldWineFinderAAnswerImage {
                  url
                  alt
                }
                fieldWineFinderAAnswerTint
                fieldWineFinderBAnswer
                fieldWineFinderBAnswerAlt {
                  url
                  alt
                }
                fieldWineFinderBAnswerImage {
                  url
                  alt
                }
                fieldWineFinderBAnswerTint
                fieldWineFinderCAnswer
                fieldWineFinderCAnswerAlt {
                  url
                  alt
                }
                fieldWineFinderCAnswerImage {
                  url
                  alt
                }
                fieldWineFinderCAnswerTint
                fieldWineFinderDAnswer
                fieldWineFinderDAnswerAlt {
                  url
                  alt
                }
                fieldWineFinderDAnswerImage {
                  url
                  alt
                }
                fieldWineFinderDAnswerTint
                fieldWineFinderEAnswer
                fieldWineFinderEAnswerAlt {
                  url
                  alt
                }
                fieldWineFinderEAnswerImage {
                  url
                  alt
                }
                fieldWineFinderEAnswerTint
              }
            }
          }
          fieldWineFinderResults {
            entity {
              ... on Drupal_ParagraphWineFinderResults {
                fieldWineFinderResultAlias
                fieldWineFinderResultProduct {
                  entity {
                    ... on Drupal_NodeWineProduct {
                      title
                      fieldProductDiscoverColorCta
                      fieldWineProductBackcolor
                      fieldWineProductBackillustrat {
                        url
                      }
                      fieldWineProductShortDesc
                      fieldWineProductName
                      fieldCategoryProductName
                      fieldWineProductDiscoverCta {
                        title
                      }
                      fieldWineProductPackshot {
                        url
                      }
                      path {
                        alias
                      }
                      ... on Drupal_NodeWineProduct {
                        fieldWineProductMetaTags {
                          entity {
                            ... on Drupal_ParagraphMetaTags {
                              fieldMetaTitle
                              fieldMetaDescription
                              fieldMetaOpenGraphImage {
                                url
                                alt
                              }
                            }
                          }
                        }
                        fieldWineProductPelorusLink {
                          uri
                        }
                        fieldWineProdTasteDescTitle
                        fieldWineProductDescription
                        fieldWineProductImageGallery {
                          entity {
                            ... on Drupal_ParagraphWineProductImageGallery {
                              fieldWineProductImageItem {
                                entity {
                                  ... on Drupal_ParagraphWineProductImageGalleryItem {
                                    fieldWineProductImage {
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        fieldWineProductPrice
                        fieldWproductBottleCapacity {
                          entity {
                            ... on Drupal_TaxonomyTermBottleCapacities {
                              name
                            }
                          }
                        }
                        fieldWineProductTemplate
                        fieldWineProductConsumption
                        fieldWineProductCellaring
                        fieldWineProductBottling
                        fieldWineProductAlcohol
                        fieldWineProductBackcolor
                        fieldWineProdFruitySavoury
                        fieldWineProdStructuralSoft
                        fieldWineProdSweetDry
                        fieldWineProdVibrantMellow
                        fieldWineProdBoldElegant
                        fieldWineProductTasteTitle
                        fieldWineProductTasteDesc
                        fieldWineProductTastesubtitle
                        fieldWineProductTastingNotes {
                          entity {
                            url
                            uri {
                              url
                            }
                          }
                        }
                        fieldWineProductTastingImage {
                          url
                          alt
                          title
                        }
                        fieldWineProduct1stKeyTaste {
                          entity {
                            ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                              fieldTastingNoteIcon {
                                url
                                title
                              }
                            }
                          }
                        }
                        fieldWineProduct2ndKeyTaste {
                          entity {
                            ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                              fieldTastingNoteIcon {
                                url
                                title
                              }
                            }
                          }
                        }
                        fieldWineProduct3rdKeyTaste {
                          entity {
                            ... on Drupal_TaxonomyTermPrimaryTastingNotes {
                              fieldTastingNoteIcon {
                                url
                                title
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldWineFinderCombinaisons
        }
      }
    }
  }
`

WineFinderPage.propTypes = {
  data: PropTypes.object
}

export default WineFinderPage
