class WineFinderManager {
  static questions = []
  static choices = []

  static parse (data) {
    return {
      questions: WineFinderManager.parseQuestions(data?.drupal?.nodeById?.fieldWineFinderQuestions),
      combinaisons: WineFinderManager.parseCombinaisons(data?.drupal?.nodeById?.fieldWineFinderCombinaisons),
      results: WineFinderManager.parseResults(data?.drupal?.nodeById?.fieldWineFinderResults)
    }
  }

  static parseQuestions (items) {
    const res = []

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const { entity } = item

      res.push({
        label: entity.fieldWineFinderQuestionLabel,
        id: i + 1,
        options: [
          {
            label: entity.fieldWineFinderAAnswer,
            color: entity.fieldWineFinderAAnswerTint,
            image: entity.fieldWineFinderAAnswerImage,
            image_colored: entity.fieldWineFinderAAnswerAlt,
            id: 'A'
          },
          {
            label: entity.fieldWineFinderBAnswer,
            color: entity.fieldWineFinderBAnswerTint,
            image: entity.fieldWineFinderBAnswerImage,
            image_colored: entity.fieldWineFinderBAnswerAlt,
            id: 'B'
          },
          {
            label: entity.fieldWineFinderCAnswer,
            color: entity.fieldWineFinderCAnswerTint,
            image: entity.fieldWineFinderCAnswerImage,
            image_colored: entity.fieldWineFinderCAnswerAlt,
            id: 'C'
          },
          {
            label: entity.fieldWineFinderDAnswer,
            color: entity.fieldWineFinderDAnswerTint,
            image: entity.fieldWineFinderDAnswerImage,
            image_colored: entity.fieldWineFinderDAnswerAlt,
            id: 'D'
          },
          {
            label: entity.fieldWineFinderEAnswer,
            color: entity.fieldWineFinderEAnswerTint,
            image: entity.fieldWineFinderEAnswerImage,
            image_colored: entity.fieldWineFinderEAnswerAlt,
            id: 'E'
          }
        ]
      })
    }

    return res
  }

  static parseCombinaisons (string) {
    return JSON.parse(string)
  }

  static parseResults (items) {
    const res = []

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const { entity } = item
      const wine = {...entity.fieldWineFinderResultProduct?.entity, id: entity.fieldWineFinderResultAlias}
      res.push(wine)
    }

    return res
  }

  static find (choices, results, combos) {
    const alias = combos.find(c => {
      return c.Q1 === choices[0] && c.Q2 === choices[1] && c.Q3 === choices[2]
    }).Wine

    return results.find(wine => wine.id.trim() === alias.trim())
  }

  static setQuestions (questions) {
    WineFinderManager.questions = questions
  }

  static setFinalChoices (choices) {
    WineFinderManager.choices = choices
  }

  static getTags () {
    const questions = WineFinderManager.questions
    const choices = WineFinderManager.choices

    const tags = []

    for (let i = 0; i < choices.length; i++) {
      const choice = choices[i]
      const question = questions[i]

      const response = question.options.find(r => r.id === choice)

      const tag = {
        url: response.image_colored.url,
        title: response.image_colored.alt
      }

      tags.push(tag)
    }

    return tags
  }
}

export default WineFinderManager
