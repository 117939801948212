import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.brown};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-weight: bold;
  font-size: 5.5rem;
  line-height: 6.5rem;
  text-align: center;
  display: inline-block;
  font-kerning: normal;

  .word {
    display: inline-flex;
    font-kerning: normal;
  }

  .char {
    overflow: hidden;
    color: transparent;
    position: relative;
    display: inline-flex;
    font-kerning: normal;

    &:after {
      content: attr(data-char);
      position: absolute;
      top: 0;
      left: 0;
      user-select: none;
      visibility: visible;
      color: ${({ theme }) => theme.colors.brown};
      transform: translateY(100%);
      transition: transform 0.5s ease;
      transition-delay: calc( 200ms + ( 30ms * ( var(--line-index)) ) );
    }
  }

  ${mediaMax.md} {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }
`

export const ChoiceIcon = styled.img`
  max-height: 5rem;
  height: 100%;
  opacity: 0;
  transition: opacity ease .3s;
  object-fit: contain;

  ${mediaMax.md} {
    max-height: 3rem;
  }
`

export const ChoiceLabel = styled.div`
  transform: translateY(100%);
  transition: transform ease .3s;
`

export const IconWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease .3s .7s;

  ${mediaMax.md} {
    transform: scale(.75);
  }
`

export const ChoiceContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  position: relative;

  ${mediaMax.md} {
    gap: 2rem;
    align-items: stretch;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    height: .1rem;
    background-color: ${({ theme }) => theme.colors.lightGreen2};
    bottom: 0;
    transition: width ease .5s .7s;
  }
`

export const Choice = styled.div`
  color: ${({ theme }) => theme.colors.brown};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-weight: bold;
  font-size: 5.5rem;
  line-height: 6.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.lightGreen2};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  overflow-y: hidden;
  cursor: pointer;
  position: relative;

  ${mediaMax.md} {
    font-size: 2.8rem;
    line-height: 3.8rem;
    gap: 2rem;
  }
`

export const ItemImages = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 6rem;
  height: 100%;
  left: 0;
  transition: opacity ease .3s ${({ index }) => `${400 + ((index + 1) * 100)}ms`};

  ${mediaMax.md} {
    max-width: 4rem;
  }
`

export const ItemImageRegular = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity .3s ease-out;
`

export const ItemImageColored = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity .3s ease-out;
`

export const ItemLabel = styled.div`
  transform: translateY(0);
  transition: transform ease .3s ${({ index }) => `${400 + (index * 100)}ms`};
`

export const Choices = styled.div`
  background-color: rgba(252, 250, 246, .95);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  gap: 2rem;
  opacity: 1;
  pointer-events: initial;
  touch-action: initial;
  transition: opacity ease .3s .1s;
  padding: 0 4rem;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    transition: opacity ease .3s .3s;

    ${ItemImages} {
      opacity: 0;
      transition: opacity ease .3s;
    }

    ${ItemLabel} {
      transform: translateY(100%);
      transition: transform ease .3s;
    }
  }
`

export const Item = styled.div`
  padding-left: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
  color: ${({ theme }) => theme.colors.lightGreen2};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-weight: bold;
  font-size: 5.5rem;
  line-height: 6.5rem;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  ${mediaMax.md} {
    padding-left: 5.5rem;
    font-size: 2rem;
    line-height: 3rem;
  }

  transition: color .3s ease-out;

  &:hover {
    color: ${({ color }) => color};

    ${ItemImageRegular} {
      opacity: 0;
    }
    ${ItemImageColored} {
      opacity: 1;
    }
  }
`

export const Content = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${ChoiceIcon} {
    opacity: 0;
  }
  ${ChoiceLabel} {
    transform: translateY(100%);
  }

  &.appear {
    ${Choice} {
      ${ChoiceContent} {
        &:after {
          width: 100%;

          ${mediaMax.md} {
            width: calc(100% - 4.5rem);
          }
        }
      }

      ${IconWrapper} {
        opacity: 1;
      }

      &.visible {
        ${ChoiceIcon} {
          opacity: 1;
          transition: opacity ease .3s .5s;
        }
        ${ChoiceLabel} {
          transform: translateY(0);
          transition: transform ease .3s .5s;
        }
      }
    }

    .char {
      &:after {
        transform: translateY(0);
      }
    }

    &.hide {
      ${Choice} {
        ${ChoiceContent} {
          &:after {
            width: 0;
            transition: width ease .3s 0s;
          }
        }

        ${IconWrapper} {
          opacity: 0;
          transition: opacity ease .3s 0s;
        }

        &.visible {
          ${ChoiceIcon} {
            opacity: 0;
            transition: opacity ease .3s 0s;
          }
          ${ChoiceLabel} {
            transform: translateY(100%);
            transition: transform ease .3s 0s;
          }
        }
      }

      ${Text} {
        opacity: 0;
        transition: opacity ease .1s .3s;
      }

      .char {
        &:after {
          opacity: 0;
          transform: translateY(100%);
          transition: transform  ease .3s 0s, opacity ease .1s .3s;
        }
      }
    }
  }
`
