import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { useRecoilValue } from 'recoil'
import { loadingState } from '@/recoil/loader'

import Question from './Question'
import Result from './Result'
import Button from '@/components/Button'
import { Transition } from 'react-transition-group'

import { WINE_FINDER_CHOICES } from '@/utils/gtmEvents'

import {
  Container,
  Wrapper,
  Header,
  Count,
  CountValue,
  Title,
  Content,
  ButtonWrapper
} from './style'
import WineFinderManager from '../../utils/WineFinderManager'

const MAX_STEP = 3

const WineFinder = ({ data, reset }) => {
  const { questions, combinaisons, results } = data
  const [choices, setChoices] = useState([])
  const [step, setStep] = useState(0)
  const [change, setChange] = useState(false)
  const [end, setEnd] = useState(false)
  const [status, setStatus] = useState(false)
  const isLoading = useRecoilValue(loadingState)
  const questionRef = useRef()
  const [wine, setWine] = useState(null)


  // const emailFieldConfig = formConfigData.find((field) => field.id === 'subscription_email_address')

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        questionRef?.current?.enter()
      })
    }
  }, [isLoading])

  const handleValidate = async () => {
    hideQuestion()
    await setChange(true)
    const questionArray = questions[step].options.find((option) => option.id === questionRef.current.choice().id)

    window.dataLayer && window.dataLayer.push({
      event: WINE_FINDER_CHOICES,
      step_name: `Step ${step + 1}`,
      step_choice: questionArray.label
    })

    const addChoice = [...choices, questionRef.current.choice().id]
    await setChoices(addChoice)

    if (step + 1 === MAX_STEP) {
      await setWine(WineFinderManager.find(addChoice, results, combinaisons))
      WineFinderManager.setQuestions(questions)
      WineFinderManager.setFinalChoices(addChoice)
      await setEnd(true)
      setTimeout(() => {
        setStatus(true)
      }, 300)
    } else {
      setTimeout(async () => {
        await setStep(step + 1)
        await setChange(false)
        showQuestion()
      }, 500)
    }
  }

  const showQuestion = () => {
    questionRef.current.show()
  }

  const hideQuestion = () => {
    questionRef.current.hide()
  }

  return (
    <Container>
      {
        !status &&
        (
          <Wrapper>
            <Header>
              <Count className={ `${!isLoading && 'enter'} ${end && 'hide'}` }>
                <CountValue className={ `${change && 'change'}` }>
                  { `0${step + 1}` }
                </CountValue>
                /{ `0${MAX_STEP}` }
              </Count>
              <Title className={ `${!isLoading && 'enter'} ${end && 'hide'}` }>Find your perfect soul mate</Title>
            </Header>
            <Content>
              <Question
                ref={ questionRef }
                text={ questions[step].label }
                options={ questions[step].options }
              />

              <ButtonWrapper className={ `${change && 'change'}` }>
                <Button ctaTracking={ false } onClick={ handleValidate }>
                  { (step + 1) < MAX_STEP ? 'Continue' : 'Reveal my soul Mate' }
                </Button>
              </ButtonWrapper>
            </Content>
          </Wrapper>
        )
      }
      <Transition in={ status } timeout={ 300 } mountOnEnter unmountOnExit>
        {(state) => (
          <Result state={ state } wine={ wine } reset={ reset } />
        )}
      </Transition>
    </Container>
  )
}

WineFinder.propTypes = {
  data: PropTypes.shape({
    questions: PropTypes.array,
    results: PropTypes.array,
    combinaisons: PropTypes.array
  })
}

export default WineFinder
