class TextSplit {
  static split (el) {
    if (el.classList.contains('splitted')) {
      return
    }

    el.normalize()
    el.classList.add('splitted')
    el.setAttribute('data-text', el.innerText)
    const elements = []
    const splitted = []
    const F = document.createDocumentFragment()

    for (let i = 0; i < el.childNodes.length; i++) {
      const child = el.childNodes[i]
      // Get the text to split, trimming out the whitespace
      /** @type {string} */
      const wholeText = child.wholeText || ''
      const contents = wholeText.trim()

      if (contents.length) {
        // insert leading space if there was one
        if (wholeText[0] === ' ') {
          elements.push(document.createTextNode.bind(document)(' '))
        }

        const words = contents.split(/\s+/)

        for (let j = 0; j < words.length; j++) {
          const word = words[j]

          if (j) {
            elements.push(TextSplit.createElement(F, 'whitespace', ' ', true))
          }

          const splitEl = TextSplit.createElement(F, 'word', word)

          const text = splitEl.innerText
          const chars = text.split('')

          const C = document.createDocumentFragment()

          for (let k = 0; k < chars.length; k++) {
            const char = chars[k]
            TextSplit.createElement(C, 'char', char)
          }

          splitEl.innerHTML = ''
          splitEl.appendChild(C)

          elements.push(splitEl)
          splitted.push(splitEl)
        }

        // insert trailing space if there was one
        if (wholeText[wholeText.length - 1] === ' ') {
          elements.push(document.createTextNode.bind(document)(' '))
        }
      }
    }

    for (let i = 0; i < elements.length; i++) {
      const s = elements[i]
      F.appendChild(s)
    }

    // Clear out the existing element
    el.innerHTML = ''
    el.appendChild(F)
    TextSplit.setProperty(el, '--words-total', splitted.length)

    function detectGrid (els) {
      const items = els
      const c = {}

      for (let i = 0; i < items.length; i++) {
        TextSplit.setProperty(items[i], '--word-index', i)

        const val = Math.round(items[i].offsetTop);
        (c[val] || (c[val] = [])).push(items[i])
      }

      return Object.keys(c).map(Number).sort(byNumber).map(selectFrom(c))
    }

    function byNumber (a, b) {
      return a - b
    }

    function selectFrom (obj) {
      return function (key) {
        return obj[key]
      }
    }

    const lines = detectGrid(splitted)

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i]
      for (let j = 0; j < line.length; j++) {
        const word = line[j]
        TextSplit.setProperty(word, '--line-index', i)
      }
    }

    TextSplit.setProperty(el, '--lines-total', lines.length)
  }

  static createElement (parent, key, text, whitespace) {
    const el = document.createElement('span')
    key && (el.className = key)

    if (text) {
      !whitespace && el.setAttribute('data-' + key, text)
      el.textContent = text
    }

    return (parent && parent.appendChild(el)) || el
  }

  static setProperty (el, varName, value) {
    el.style.setProperty(varName, value)
  }

  static reset (el, text = null) {
    el.innerHTML = text || el.dataset.text
    el.classList.remove('splitted')
  }

  static isSplitted (el) {
    return el.classList.contains('splitted')
  }
}

export default TextSplit
