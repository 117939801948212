import styled from '@emotion/styled'

import { sectionTitle, sectionSubtitle, mediaMax } from '@/styles/mixins'

import {
  Image as TagImage,
  Container as TagContainer
} from '@/components/Taste/Tags/Item/style'

import {
  Container as TagsContainer
} from '@/components/Taste/Tags/style'

import {
  Container as TastesContainer
} from '@/components/Taste/Table/style'

import {
  ButtonWrapper
} from '@/components/Button/style'

import {
  LinkWrapper
} from '@/components/Link/style'

import CircularLabelButton from '@/components/CircularLabelButton'

export const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.5rem;

  ${mediaMax.md} {
    width: 100%;
  }


  ${TagsContainer} {
    &.small {
      ${TagContainer} {
        margin-left: 0;
        margin-right: 3rem;
      }

      ${TagImage} {
        width: 6.6rem;
        height: 6.6rem;
      }
    }
  }
`

export const Subtitle = styled.div`
  ${sectionSubtitle};
`

export const Title = styled.div`
  ${sectionTitle};
  color: ${({ theme }) => theme.colors.brown};
`

export const Text = styled.p`
  width: 80%;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.grey};
  white-space: pre-wrap
`

export const RightWrapper = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMax.md} {
    width: 100%;
    margin-bottom: 15vh;
  }
`

export const ArtCircle = styled.div`
  width: 65%;
  padding-top: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ color }) => color};
  background-image: url(${({ image }) => image});
  background-position: 50% 50%;
  background-size: 95%;
  background-repeat: repeat;
  opacity: 1;
  transition: opacity ease 1s .5s, background-position ease .3s, clip-path ease 1s;
  border-radius: 50%;
`

export const Bottle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  position: relative;
`

export const Image = styled.img`
  width: 35%;
`

export const Name = styled.div`
  position: absolute;
  top: calc(100% + 5.3rem);
  font-size: 3.5rem;
  font-family: ${({ theme }) => theme.fonts.serif};
  color: ${({ theme }) => theme.colors.brown};
  font-weight: bold;
`

export const CircularButton = styled(CircularLabelButton)`
  position: absolute;
  right: 10rem;
  bottom: 10rem;
  z-index: 2;

  ${mediaMax.md} {
    display: none;
    // transform: rotate(90deg);
    // right: 3rem;
    // bottom: 12rem;
  }
`

export const ActionsWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5.4rem;

  a, a:visited {
    color: ${({ theme }) => theme.colors.grey};
    text-decoration: none;
  }

  ${mediaMax.md} {
    width: 100%;
    z-index: 999;
    position: fixed;
    align-items: stretch;
    bottom: 0;
    left: 0;
    gap: 0;

    ${ButtonWrapper} {
      width: 50%;
    }

    ${LinkWrapper} {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13rem;
  transition: all ease .5s;

  ${mediaMax.md} {
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
    height: auto;
    padding: 10vh 1.5rem 0;
    flex-direction: column;
    gap: 10rem;
  }

  ${ArtCircle} {
    opacity: 0;
    transition: all ease .3s;
  }
  ${Image} {
    opacity: 0;
    transition: opacity ease .3s .3s;
  }
  ${Name} {
    opacity: 0;
    transition: opacity ease .3s .6s;
  }
  ${CircularButton} {
    // transform: scale(0);
    opacity: 0;
    transition: all ease-out .5s .5s;
  }
  ${Subtitle} {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all ease-out .5s .3s;
  }
  ${Title} {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all ease-out .5s .3s;
  }
  ${Text} {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all ease-out .5s .3s;

    ${mediaMax.md} {
      width: 100%;
    }
  }
  ${TagContainer} {
    opacity: 0;
    transition: opacity ease-out .5s .3s;
  }
  ${TastesContainer} {
    opacity: 0;
    transition: opacity ease-out .5s .3s;
  }
  ${ActionsWrapper} {
    opacity: 0;
    transition: opacity ease-out .5s .3s;
  }

  &.transition-entering {
    ${ArtCircle} {
      opacity: 0;
    }
    ${Image} {
      opacity: 0;
    }
    ${Name} {
      opacity: 0;
    }
    ${CircularButton} {
      // transform: scale(0);
      opacity: 0;
    }

    ${Subtitle} {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    ${Title} {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    ${Text} {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    ${TagContainer} {
      opacity: 0;
    }

    ${TastesContainer} {
      opacity: 0;
    }

    ${ActionsWrapper} {
      opacity: 0;
    }
  }

  &.transition-entered {
    ${ArtCircle} {
      opacity: 1;
    }
    ${Image} {
      opacity: 1;
    }
    ${Name} {
      opacity: 1;
    }
    ${CircularButton} {
      // transform: scale(1.3);
      opacity: 1;

      ${mediaMax.md} {
        transform: scale(1) rotate(90deg);
      }
    }

    ${Subtitle},
    ${Title},
    ${Text} {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    ${TagContainer} {
      opacity: 1;
    }

    ${TastesContainer} {
      opacity: 1;
    }

    ${ActionsWrapper} {
      opacity: 1;
    }
  }
`
