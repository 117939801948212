import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Tags from '@/components/Taste/Tags'
import Table from '@/components/Taste/Table'
import Button from '@/components/Button'
import Link from '@/components/Link'

import resolveAssetURL from '@/utils/resolveAssetURL'

import { WINE_FINDER_CLICK, WINE_FINDER_RESULTS } from '@/utils/gtmEvents'

import {
  Container,
  LeftWrapper,
  Subtitle,
  Title,
  Text,
  RightWrapper,
  ArtCircle,
  Bottle,
  Image,
  Name,
  CircularButton,
  ActionsWrapper
} from './style'

import WineFinderManager from '../../../utils/WineFinderManager'

const Result = ({ state = '', wine = null, reset = _ => {} }) => {
  const tags = WineFinderManager.getTags()

  const tastes = [
    { min: 'Fruity', max: 'Savoury', value: wine?.fieldWineProdFruitySavoury },
    { min: 'Structural', max: 'Soft', value: wine?.fieldWineProdStructuralSoft },
    { min: 'Sweet', max: 'Dry', value: wine?.fieldWineProdSweetDry },
    { min: 'Vibrant', max: 'Mellow', value: wine?.fieldWineProdVibrantMellow },
    { min: 'Bold', max: 'Elegant', value: wine?.fieldWineProdBoldElegant }
  ]

  const gtm = () => {
    window.dataLayer && window.dataLayer.push({
      event: WINE_FINDER_CLICK,
      productName: `${wine?.title}`
    })
  }

  useEffect(() => {
    window.dataLayer && window.dataLayer.push({
      event: WINE_FINDER_RESULTS,
      productName: `${wine?.title}`
    })
  }, [wine])

  return (
    <Container className={ `transition-${state}` }>
      <LeftWrapper>
        <Subtitle>
          Results
        </Subtitle>
        <Title>Your perfect soul mate is { wine?.title }</Title>
        <Tags items={ tags } display='small' />
        <Text>{ wine?.fieldWineProductTasteDesc }</Text>
        <Table items={ tastes.filter((taste) => !!taste.value) } />
        <ActionsWrapper>
          <Button
            type='green'
            onClick={ gtm }
            to={ wine?.path.alias }
            ctaTracking={ false }
          >
            Shop my soul mate
          </Button>
          <Link
            onClick={ reset }
          >
            Restart
          </Link>
        </ActionsWrapper>
      </LeftWrapper>
      <RightWrapper>
        <ArtCircle
          color={ wine?.fieldWineProductBackcolor }
          image={ resolveAssetURL(wine?.fieldWineProductBackillustrat?.url) }
        />
        <Bottle>
          <Image loading='lazy' src={ resolveAssetURL(wine?.fieldWineProductPackshot?.url) } />
          <Name>
            { wine?.title }
          </Name>
        </Bottle>
      </RightWrapper>
      <CircularButton
        id="textcercle"
        icon='arrow-right'
        label='All our wines'
        nbLabels={ 4 }
        to='/our-wines'
      />
    </Container>
  )
}

Result.propTypes = {
  state: PropTypes.string
}

export default Result
